import Data from 'bootstrap/js/src/dom/data';
import EventHandler from 'bootstrap/js/src/dom/event-handler';
import Manipulator from 'bootstrap/js/src/dom/manipulator';
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine';

import Evenement from '../components/evenement';

/***
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const DATA_TARGET_KEY = 'app.validation.target';

const SELECTOR_EVENEMENTS_COUNTER = '.evenements-counter';
const SELECTOR_EVENEMENTS_CONTAINER = '.list-group';
const SELECTOR_DATA_EVENEMENT = '[data-evenement]';
const SELECTOR_DATA_PERSONNE = '[data-personne]';
const SELECTOR_DATA_VALIDATE = '[data-validate]';
const SELECTOR_DATA_VALIDATE_URL = '[data-validate-url]';

/***
 * ------------------------------------------------------------------------
 * Évènements
 * ------------------------------------------------------------------------
 */

/**
 * Valide l'évènement au clic sur un élément avec l'attribut `data-validate`.
 */
EventHandler.on(document, 'click', SELECTOR_DATA_VALIDATE, function (event) {
  if (this.tagName === 'A') {
    event.preventDefault();
  }

  // Récupère ou cherche l'élément de l'évènement
  const target = Data.getData(this, DATA_TARGET_KEY) ||
    this.closest(SELECTOR_DATA_VALIDATE_URL);
  if (!target) {
    throw new Error(
      `Impossible de trouver l'élément ${SELECTOR_DATA_VALIDATE_URL}.`
    );
  }

  // Récupère l'instance de l'évènement ou la crée
  const evenement = Evenement.getInstance(target) ||
    new Evenement(target, Manipulator.getDataAttributes(this));
  const allow = Manipulator.getDataAttribute(this, 'validate');

  evenement.validate(allow);
});

/**
 * Mets à jour le compteur d'une personne à la validation d'un évènement et
 * supprime si besoin l'élément correspondant.
 */
EventHandler.on(document, 'validated.app.validation', SELECTOR_DATA_PERSONNE, function (event) {
  if (this.contains(event.target)) {
    event.target.parentNode.removeChild(event.target);
  }

  const nbEvenements = SelectorEngine.find(SELECTOR_DATA_EVENEMENT, this).length;

  SelectorEngine.find(SELECTOR_EVENEMENTS_COUNTER, this)
    .forEach(element => {
      element.innerHTML = nbEvenements;
    });

  if (nbEvenements === 0) {
    SelectorEngine.findOne(SELECTOR_EVENEMENTS_CONTAINER, this)
      .innerHTML = '<div class="list-group-item">Tous les évènements ont été validés.</div>';

    if (this.elements && this.elements.length) {
      // désactive tous les éléments du formulaire
      for (let i = 0; i < this.elements.length; i++) {
        this.elements[i].setAttribute('disabled', '');
      }
    }
  }
});
