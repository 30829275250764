import { typeCheckConfig } from 'bootstrap/js/src/util/index';
import Data from 'bootstrap/js/src/dom/data';
import EventHandler from 'bootstrap/js/src/dom/event-handler';
import Manipulator from 'bootstrap/js/src/dom/manipulator';
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine';

import request from '../vendor/superagent';

import Message from './message';
import Modal from './modal';

/***
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const NAME = 'validation';
const DATA_KEY = `app.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;

const Default = {
  evenement: null,
  validateUrl: ''
};

const DefaultType = {
  evenement: 'number',
  validateUrl: 'string'
};

const EVENT_VALIDATE = `validate${EVENT_KEY}`;
const EVENT_VALIDATED = `validated${EVENT_KEY}`;
const EVENT_CLICK_SUBMIT = `click.submit${EVENT_KEY}`;

const SELECTOR_SUBMIT = '[type="submit"]';

const TEMPLATE_COMMENT_MODAL =
  '<textarea class="form-control" name="commentaire" placeholder="Commentaire de refus…" rows="3" required></textarea>';

/***
 * ------------------------------------------------------------------------
 * Méthodes
 * ------------------------------------------------------------------------
 */

function CommentModal(title) {
  const modal = new Modal({
    title,
    content: TEMPLATE_COMMENT_MODAL,
    headerClose: false,
    buttons: [
      {
        text: 'Annuler',
        value: false,
        attr: {
          'class': 'btn btn-default',
          'data-dismiss': 'modal'
        }
      },
      {
        text: 'Refuser',
        value: true,
        attr: {
          class: 'btn btn-primary',
          type: 'submit'
        }
      }
    ]
  });

  const commentElement = SelectorEngine.findOne('[name="commentaire"]', modal._html.body);

  EventHandler.on(modal._html.footer, EVENT_CLICK_SUBMIT, SELECTOR_SUBMIT, () => {
    commentElement.setCustomValidity('');

    if (commentElement.checkValidity()) {
      modal.emit('submit', modal, commentElement.value);
      modal.hide();
    } else {
      commentElement.setCustomValidity('Le commentaire est requis.');
    }
  });

  return modal;
}

/***
 * ------------------------------------------------------------------------
 * Classes
 * ------------------------------------------------------------------------
 */

class Evenement {
  constructor(element, config) {
    this._element = element;
    this._config = this._getConfig(config);
    this._isValidating = false;

    if (!this._config.validateUrl) {
      throw new Error('Le lien de validation est manquant.');
    }

    Data.setData(element, DATA_KEY, this);
  }

  // Getters

  get id() {
    return this._config.evenement;
  }

  static get Default() {
    return Default;
  }

  // Public

  validate(allow) {
    const validateEvent = EventHandler.trigger(this._element, EVENT_VALIDATE, {
      id: this.id, allow
    });

    if (this._isValidating || validateEvent.defaultPrevented) {
      return;
    }

    this._isValidating = true;
    this._element.setAttribute('disabled', '');

    if (allow) {
      this._validate(true);
    } else {
      CommentModal(`Refuser l'évènement (${this.id})`)
        .once('dismiss', () => {
          this._isValidating = false;
          this._element.removeAttribute('disabled');
        })
        .once('submit', (modal, comment) => {
          this._validate(false, comment);
        })
        .show();
    }
  }

  // Private

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...typeof config === 'object' && config ? config : {}
    };

    typeCheckConfig(NAME, config, DefaultType);

    return config;
  }

  _validate(allow, comment) {
    request
      .put(this._config.validateUrl)
      .send({ allow, comment })
      .accept('json')
      .end((error, response) => {
        this._isValidating = false;
        this._element.removeAttribute('disabled');

        const data = response.body;

        if (response.ok) {
          Message.success(
            data.detail ||
            `L'évènement (${this.id}) a été validé.`
          );

          EventHandler.trigger(this._element, EVENT_VALIDATED, {
            id: this.id, allow
          });
        } else {
          Message.error(
            data.detail ||
            `Impossible de valider l'évènement (${this.id}), une erreur inattendue est survenue.`
          );
        }
      });
  }

  // Static

  static getInstance(element) {
    return Data.getData(element, DATA_KEY);
  }
}

export default Evenement;
