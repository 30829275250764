import EventHandler from 'bootstrap/js/src/dom/event-handler';
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine';

import ModalVanilla from 'modal-vanilla';
import request from '../vendor/superagent';

/***
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const NAME = 'modal';
const DATA_KEY = `app.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;

const EVENT_CONTENTCHANGE = `contentchange${EVENT_KEY}`;
const EVENT_CLICK_SUBMIT = `click.submit${EVENT_KEY}`;

const SELECTOR_SUBMIT = '[type="submit"]';

/***
 * ------------------------------------------------------------------------
 * Configuration
 * ------------------------------------------------------------------------
 * voir : https://github.com/KaneCohen/modal-vanilla
 */

ModalVanilla.buttons = {
  confirm: [
    {
      text: 'Annuler',
      value: false,
      attr: {
        'class': 'btn btn-default',
        'data-dismiss': 'modal'
      }
    },
    {
      text: 'OK',
      value: true,
      attr: {
        'class': 'btn btn-primary',
        'data-dismiss': 'modal'
      }
    }
  ]
};

ModalVanilla.templates = {
  container: '<div class="modal" tabindex="-1" role="dialog"></div>',
  dialog: '<div class="modal-dialog" role="document">',
  headerClose: '<button type="button" class="close" data-dismiss="modal" aria-label="Fermer"><span aria-hidden="true">&times;</span></button>'
};

/***
 * ------------------------------------------------------------------------
 * Classes
 * ------------------------------------------------------------------------
 */

/**
 * Étends les fonctionnalités de modal-vanilla afin de :
 *  - pouvoir charger une URL via `Modal.fromURL()`
 *  - définir la taille de la modale via `options.size` (sm, lg ou xl)
 *  - afficher la modale une fois instanciée via `options.show`
 */
class Modal extends ModalVanilla {
  constructor(options = {}) {
    super(options);

    if (options.size) {
      this._html.dialog.classList.add(`modal-${options.size}`);
    }

    if (options.show) {
      this.show();
    }
  }

  // Private

  _setEvents() {
    super._setEvents();

    EventHandler.on(this._html.footer,
      EVENT_CLICK_SUBMIT,
      SELECTOR_SUBMIT,
      event => this._handleSubmitForm(event)
    );
  }

  _removeEvents() {
    super._removeEvents();

    EventHandler.off(this._html.footer, EVENT_CLICK_SUBMIT);
  }

  _handleSubmitForm(event) {
    const form = event.target.hasAttribute('form') ?
      document.getElementById(event.target.getAttribute('form')) :
      SelectorEngine.findOne('form', this._html.body);

    if (!form) {
      return;
    }

    const url = event.target.getAttribute('formaction') || form.getAttribute('action');

    if (!url || url === '.') {
      throw new Error('Undefined or invalid form action');
    }

    request.post(url)
      .accept('application/json')
      .send(new FormData(form))
      .then(response => {
        this.emit('submit', this, response);
        this.hide();
      })
      .catch(err => {
        if (!err.response.body || !err.response.body.content) {
          throw new Error('Unexpected response while submitting the form');
        }

        this._html.body.innerHTML = err.response.body.content;

        EventHandler.trigger(document, EVENT_CONTENTCHANGE, {
          body: this._html.body,
          modal: this
        });
      });

    return false;
  }

  // Static

  /**
   * Charge une URL et crée un objet Modal avec sa réponse.
   *
   * La réponse retournée par l'URL doit être au format JSON avec les
   * propriétés suivantes :
   *  - title : le titre de la fenêtre modale
   *  - content : le contenu au format HTML
   *
   * En cas de réussite, la fenêtre est directement affichée. Il est
   * possible de modifier ce comportement en définissant `show: false`.
   *
   * Cette méthode retourne un objet Promise auquel on peut attacher deux
   * *callback* via la méthode `then()`, pouvant prendre comme argument :
   *  - (modal) : en cas de réussite
   *  - (response) : en cas d'échec
   *
   * @param {String} url - L'URL à charger
   * @param {Object} [options] - Des options à passer à l'objet Modal
   * @returns {Promise} - L'objet Promise du traitement du chargement
   */
  static fromURL(url, options = {}) {
    return new Promise((resolve, reject) => {
      request.get(url)
        .accept('application/json')
        .then(response => {
          if (!response.body) {
            throw new Error(
              `La réponse de '${response.req.url}' n'est pas au format JSON.`
            );
          } else if (!response.body.content) {
            throw new Error(
              `La réponse de '${response.req.url}' ne définis pas de contenu.`
            );
          }

          const title = response.body.title || options.title || '';
          const footer = response.body.footer || options.footer || false;
          const buttons = footer && typeof footer !== 'string' ?
            footer :
            options.buttons;

          // instancie l'objet avec ses options
          const modal = new Modal({
            title,
            content: response.body.content,
            header: title !== '',
            footer: buttons ? true : footer,
            buttons: buttons || null,
            ...options
          });

          EventHandler.trigger(document, EVENT_CONTENTCHANGE, {
            body: modal._html.body,
            modal
          });

          if (options.show !== false) {
            modal.show();
          }

          resolve(modal);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
}

export default Modal;
