// eslint-disable-next-line no-undef,camelcase
__webpack_public_path__ = window.STATIC_URL || '/';

import './vendor/bootstrap';

import './components/formset';

import './views/evenement-form';
import './views/evenement-validation';

import { getSelectorFromElement } from 'bootstrap/js/src/util/index';
import EventHandler from 'bootstrap/js/src/dom/event-handler';
import Manipulator from 'bootstrap/js/src/dom/manipulator';
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine';

import Message from './components/message';
import Modal from './components/modal';

/***
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const EVENT_KEY = '.app';

const EVENT_CLICK = `click${EVENT_KEY}`;
const EVENT_CHANGE = `change${EVENT_KEY}`;
const EVENT_LOAD = `load${EVENT_KEY}`;

const SELECTOR_DATA_CALENDAR = '[data-calendar]';
const SELECTOR_DATA_TOGGLE_CHECK = '[data-toggle="check"], [data-toggle="uncheck"]';
const SELECTOR_DATA_TOGGLE_ENABLE = '[data-toggle="enable"]';
const SELECTOR_DATA_TOGGLE_AJAX_MODAL = '[data-toggle="ajax-modal"]';

/***
 * ------------------------------------------------------------------------
 * Méthodes
 * ------------------------------------------------------------------------
 */

/**
 * Charge dynamiquement les calendriers avec le composant Calendar.
 */
function initCalendars(calendars) {
  if (!calendars.length) {
    return;
  }

  import(/* webpackChunkName: "calendar" */ './components/calendar.js')
    .then(({ default: Calendar }) => {
      calendars.forEach(calendar => Calendar.calendarInterface(calendar));
    });
}

/***
 * ------------------------------------------------------------------------
 * Évènements
 * ------------------------------------------------------------------------
 */

/**
 * Coche/décoche une ou plusieurs checkbox au clic sur un élément. L'état
 * est défini soit via l'état de l'élément si celui-ci est un champ de
 * type 'checkbox', soit la valeur de son attribut `data-toggle`.
 */
EventHandler.on(document, EVENT_CLICK, SELECTOR_DATA_TOGGLE_CHECK, function (event) {
  if (event.target.tagName === 'A') {
    event.preventDefault();
  }

  const selector = getSelectorFromElement(this);
  const checked = (typeof this.checked === 'boolean') ?
    this.checked :
    Manipulator.getDataAttribute(this, 'toggle');

  SelectorEngine.find(selector)
    .forEach(element => {
      if (element.tagName === 'INPUT' && element.type === 'checkbox') {
        element.checked = checked;
      }
    });
});

/**
 * Active un ou plusieurs éléments cibles lorsqu'un élément (généralement
 * un champ de type 'radio') change. Il est possible de lier des cibles via
 * l'attribut `data-group`, afin qu'une seule ne soit active à la fois.
 */
EventHandler.on(document, EVENT_CHANGE, SELECTOR_DATA_TOGGLE_ENABLE, function (event) {
  if (!event.target.checked) {
    return;
  }

  const selector = getSelectorFromElement(this);

  SelectorEngine.find(selector)
    .forEach(element => {
      element.removeAttribute('disabled');

      const group = Manipulator.getDataAttribute(element, 'group');

      if (group) {
        SelectorEngine.find(`[data-group="${group}"]`)
          .forEach(groupElement => {
            if (groupElement !== element) {
              groupElement.setAttribute('disabled', '');
            }
          });
      }
    });
});

/**
 * Charge une page dans une fenêtre modale au clic sur un élément.
 */
EventHandler.on(document, EVENT_CLICK, SELECTOR_DATA_TOGGLE_AJAX_MODAL, function (event) {
  if (this.tagName === 'A') {
    event.preventDefault();
  }

  if (!this.hasAttribute('disabled')) {
    const target = this.getAttribute('href');

    this.setAttribute('disabled', '');

    Modal.fromURL(target)
      .catch(resp => {
        Message.error(`
          Impossible de charger la page demandée.<br>
          <small class="text-black-50">Erreur : ${resp.statusText} (${resp.req.url})</small>
        `);
      }).finally(() => {
        this.removeAttribute('disabled');
      });
  }
});

/***
 * ------------------------------------------------------------------------
 * Initialisation
 * ------------------------------------------------------------------------
 */

EventHandler.on(window, EVENT_LOAD, () => {
  initCalendars(SelectorEngine.find(SELECTOR_DATA_CALENDAR));

  // Supprime la classe .no-js une fois la page chargée
  document.documentElement.classList
    .remove('no-js');
});
