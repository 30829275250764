import {
  DefaultWhitelist,
  sanitizeHtml as sanitizeHtmlBase
} from 'bootstrap/js/src/util/sanitizer';

/**
 * Nettoie du code HTML.
 * @param {String} html - Le code HTML à créer
 * @returns {String}
 */
export function sanitizeHtml(unsafeHtml) {
  return sanitizeHtmlBase(unsafeHtml, DefaultWhitelist);
}

/**
 * Crée un élément à partir du code HTML.
 * @param {String} html - Le code HTML à créer
 * @param {Boolean} sanitize - Si le code doit être nettoyé
 * @returns {Element}
 */
export function createElementFromHtml(html, sanitize = true) {
  if (html.nodeName) {
    return html;
  }

  const element = document.createElement('div');

  if (sanitize) {
    html = sanitizeHtml(html);
  }

  element.innerHTML = html;
  return element.children[0];
}
