import {
  TRANSITION_END,
  emulateTransitionEnd,
  getTransitionDurationFromElement,
  isElement,
  typeCheckConfig
} from 'bootstrap/js/src/util/index';
import Data from 'bootstrap/js/src/dom/data';
import EventHandler from 'bootstrap/js/src/dom/event-handler';
import Manipulator from 'bootstrap/js/src/dom/manipulator';
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine';

import { createElementFromHtml, sanitizeHtml } from '../util/dom';

/***
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const NAME = 'message';
const DATA_KEY = `app.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;

const Default = {
  animation: true,
  autohide: true,
  delay: 3000
};

const DefaultType = {
  animation: 'boolean',
  autohide: 'boolean',
  delay: 'number'
};

const EVENT_CLICK_DISMISS = `click.dismiss${EVENT_KEY}`;
const EVENT_CLOSE = `close${EVENT_KEY}`;
const EVENT_CLOSED = `closed${EVENT_KEY}`;
const EVENT_MOUSEENTER = `mouseenter${EVENT_KEY}`;
const EVENT_LOAD = `load${EVENT_KEY}`;

const CLASS_NAME_DISMISSIBLE = 'alert-dismissible';
const CLASS_NAME_FADE = 'fade';
const CLASS_NAME_SHOW = 'show';

const SELECTOR_ALERT_BODY = '.alert-body';
const SELECTOR_HOLDER = '.messages-holder';
const SELECTOR_MESSAGE = `${SELECTOR_HOLDER} .alert`;
const SELECTOR_DATA_DISMISS = '[data-dismiss="message"]';

const TEMPLATE_ALERT =
  '<div class="alert" role="alert" aria-live="assertive" aria-atomic="true">' +
    '<div class="alert-body"></div></div>';
const TEMPLATE_CLOSE_BUTTON =
  '<button type="button" class="close" data-dismiss="message" aria-label="Fermer">' +
    '<span aria-hidden="true">&times;</span></button>';

/***
 * ------------------------------------------------------------------------
 * Classes
 * ------------------------------------------------------------------------
 */

class Message {
  constructor(element, config) {
    this._element = element;
    this._config = this._getConfig(config);
    this._timeout = null;

    if (this._config.animation) {
      element.classList.add(CLASS_NAME_FADE, CLASS_NAME_SHOW);
    }

    this._setListeners();

    Data.setData(element, DATA_KEY, this);
  }

  // Getters

  static get DefaultType() {
    return DefaultType;
  }

  static get Default() {
    return Default;
  }

  // Public

  close() {
    const closeEvent = EventHandler.trigger(this._element, EVENT_CLOSE);

    if (closeEvent.defaultPrevented) {
      return;
    }

    const complete = () => {
      if (this._element.parentNode) {
        this._element.parentNode.removeChild(this._element);
      }

      EventHandler.trigger(this._element, EVENT_CLOSED);
    };

    this._element.classList.remove(CLASS_NAME_SHOW);

    if (this._config.animation) {
      const transitionDuration = getTransitionDurationFromElement(this._element);

      EventHandler.one(this._element, TRANSITION_END, complete);
      emulateTransitionEnd(this._element, transitionDuration);
    } else {
      complete();
    }
  }

  dispose() {
    this._clearTimeout();

    EventHandler.off(this._element, EVENT_CLICK_DISMISS);
    EventHandler.off(this._element, EVENT_MOUSEENTER);
    Data.removeData(this._element, DATA_KEY);

    this._element = null;
    this._config = null;
  }

  // Private

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...typeof config === 'object' && config ? config : {}
    };

    typeCheckConfig(NAME, config, DefaultType);

    return config;
  }

  _setListeners() {
    EventHandler
      .on(this._element, EVENT_CLICK_DISMISS, SELECTOR_DATA_DISMISS, () => this.close());

    if (this._config.autohide) {
      this._timeout = setTimeout(() => {
        this.close();
      }, this._config.delay);

      EventHandler
        .one(this._element, EVENT_MOUSEENTER, () => this._clearTimeout());
    }
  }

  _clearTimeout() {
    clearTimeout(this._timeout);
    this._timeout = null;
  }

  // Static

  static addMessage(level, content, dismissible = true, config = {}) {
    const element = createElementFromHtml(TEMPLATE_ALERT, false);
    element.classList.add(`alert-${level}`);

    if (dismissible) {
      element.appendChild(createElementFromHtml(TEMPLATE_CLOSE_BUTTON, false));
      element.classList.add(CLASS_NAME_DISMISSIBLE);
    } else if (config.authide !== true) {
      config.autohide = false;
    }

    // Set the message content
    const body = SelectorEngine.findOne(SELECTOR_ALERT_BODY, element);

    if (typeof content === 'object' && isElement(content)) {
      body.innerHTML = '';
      body.appendChild(content);
    } else {
      body.innerHTML = sanitizeHtml(content);
    }

    // Append the message and return it
    SelectorEngine.findOne(SELECTOR_HOLDER)
      .appendChild(element);

    return new Message(element, config);
  }

  static success(...args) {
    return Message.addMessage('success', ...args);
  }

  static warning(...args) {
    return Message.addMessage('warning', ...args);
  }

  static error(content, dismissible, config) {
    config = {
      autohide: false,
      ...typeof config === 'object' && config ? config : {}
    };

    return Message.addMessage('danger', content, dismissible, config);
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY);
  }
}

/***
 * ------------------------------------------------------------------------
 * Initialisation
 * ------------------------------------------------------------------------
 */

EventHandler.on(window, EVENT_LOAD, () => {
  SelectorEngine.find(SELECTOR_MESSAGE)
    .forEach(message => new Message(message));
});

export default Message;
