import request from '../vendor/superagent';

import EventHandler from 'bootstrap/js/src/dom/event-handler';
import Manipulator from 'bootstrap/js/src/dom/manipulator';
import SelectorEngine from 'bootstrap/js/src/dom/selector-engine';

/***
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const SELECTOR_ACTION_INPUT = '#id_action_particuliere';
const SELECTOR_RUBRIQUE_INPUT = '#id_rubrique';

/***
 * ------------------------------------------------------------------------
 * Méthodes
 * ------------------------------------------------------------------------
 */

function initActionInput(actionElement) {
  if (!actionElement) {
    return;
  }

  const rubriqueElement = SelectorEngine.findOne(
    SELECTOR_RUBRIQUE_INPUT, actionElement.closest('form')
  );
  const listApiUrl = Manipulator.getDataAttribute(actionElement, 'listApiUrl');

  if (!rubriqueElement || !listApiUrl) {
    return;
  }

  request.get(listApiUrl)
    .accept('application/json')
    .then(response => {
      if (!response.body) {
        return;
      }

      // transforme le tableau afin d'utiliser l'id de l'action comme clé
      const actionsMap = new Map(response.body.map(obj => [obj.id, obj]));

      const updateRubriqueOptions = () => {
        const actionId = parseInt(actionElement.value, 10);

        if (Number.isNaN(actionId) || !actionsMap.has(actionId)) {
          // active toutes les rubriques
          for (let i = 0; i < rubriqueElement.length; i++) {
            rubriqueElement.options[i].disabled = false;
          }
        } else {
          const rubriques = actionsMap.get(actionId).rubriques;

          // (dés)active les rubriques en fonction de l'action
          for (let i = 0; i < rubriqueElement.length; i++) {
            const option = rubriqueElement.options[i];
            const value = parseInt(option.value, 10);

            option.disabled = rubriques.indexOf(value) < 0;
          }

          // vérifie l'état de la rubrique sélectionnée
          if (rubriqueElement.selectedOptions[0].disabled) {
            rubriqueElement.selectedIndex = 0;
          }
        }
      };

      EventHandler.on(actionElement, 'change', updateRubriqueOptions);

      updateRubriqueOptions();
    });
}

/***
 * ------------------------------------------------------------------------
 * Initialisation
 * ------------------------------------------------------------------------
 */

EventHandler.on(window, 'load', () => {
  initActionInput(SelectorEngine.findOne(SELECTOR_ACTION_INPUT));

  EventHandler.on(document, 'contentchange.app.modal', ({ body }) => {
    initActionInput(SelectorEngine.findOne(SELECTOR_ACTION_INPUT, body));
  });
});
